@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

header {
  height: 70px;
  background-color: #1789FC;
}

footer {
  background-color: #F3F3F3;
}

a:hover {
  color: #1789FC;
}

h1 {
  font-size: 70px;
  font-weight: 700;
  line-height: 77px;
  letter-spacing: -2.1px;
}

h2 {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  /* 138.889% */
  letter-spacing: -1.08px;
}

h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 125% */
  letter-spacing: -0.72px;
}

h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 125% */
  letter-spacing: -0.72px;
}

p {
  color: #464853;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.54px;
}

strong {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.54px;
}

html {
  font-family: 'Inter', sans-serif;
}

.button-variant-1 {
  background-color: #1789FC;
  color: #fff;
  width: 290px;
  height: 48px;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.54px;
  border: none;
  cursor: pointer;
}

.button-variant-2 {
  background-color: #fff;
  width: 290px;
  height: 48px;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.54px;
  border: none;
  cursor: pointer;
  border: 1px solid #A6A6A6;
}

.common-card {
  border-radius: 10px;
  background: rgba(243, 243, 243, 0.50);
}

.tool-card {
  display: flex;
  padding: 55px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 14px;
  background: #F3F3F3;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 290px;

  a:hover {
    color: inherit;
  }
}

.new-tool-badge {
  border-radius: 4px;
  background: #BEFFCA;
  color: #00e92b;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
  /* 0% */
  letter-spacing: -0.3px;
  text-transform: uppercase;
  margin-left: 7px;
  padding: 9px;
}

.tool-wrapper>li:hover>a>p {
  display: none;
}

.tool-wrapper>li:first-of-type>a:hover {
  background: rgba(86, 203, 249, 0.41) !important;
  color: #43B2DD;
}

.tool-wrapper>li:nth-of-type(2)>a:hover {
  background: rgba(97, 255, 126, 0.41) !important;
  color: #61FF7E;
}

.tool-wrapper>li:nth-of-type(3)>a:hover {
  background: rgba(239, 71, 111, 0.41) !important;
  color: #EF476F;
}

.tool-wrapper>li:nth-of-type(4)>a:hover {
  background: rgba(247, 159, 121, 0.41) !important;
  color: #F79F79;
}

.json-input{
  border-radius: 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important
}